.navButtonTxt {
  font-size: 14px;
  text-transform: capitalize !important;
  color: #0e1c4e;
  font-weight: 600;
}

.primaryBtnAccent {
  background-color: #fa7e61 !important;
  color: #0e1c4e !important;
  text-transform: capitalize;
  border: none !important;
}

.primaryBtnAccent:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.primaryBtnAccent:hover {
  background-color: #fa7e61 !important;
  color: #0e1c4e !important;
}

.primaryTxtAccent {
  color: #fa7e61;
}

.primaryTxtAccentForce {
  color: #fa7e61 !important;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-padding-adjust {
  padding-top: 32;
  padding-left: 72;
}

.navyBlueColor {
  color: #0e1c4e;
}

.navyBlueColorForce {
  color: #0e1c4e !important;
}

.sqFlexBoxCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.primaryBackground {
  background-color: #0e1c4e;
}

.sqGreyBorder {
  border: 1px solid #17174c1f;
}

.sqEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.sqEllipsisThreeLines {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-all;
}

.sqEllipsisTwoLines {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-all;
}
.sqEllipsisOneLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-all;
}
.sqErrorColor {
  color: #d90368;
}
.sqDividerColor {
  color: rgba(14, 28, 78, 0.04);
}

.sqTileBorderColor {
  border: 2px solid rgba(14, 28, 78, 0.12) !important;
}

.sqGrayBackgroundColor {
  background-color: rgba(14, 28, 78, 0.04);
}

/* font 14px */
.font14 {
  font-size: 0.875rem !important;
}
.categoryText {
  font-size: 0.83rem !important;
}
.msgUpdateIndicator {
  font-size: 16px;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.toasterSuccess {
  background-color: #c3dece;
  color: black;
}
.toasterWarning {
  background-color: #ffefbf;
  color: black;
}
.toasterError {
  background-color: #eebfbf;
  color: black;
}
.toasterInfo {
  background-color: #c9cff3;
  color: black;
}
.indicator {
  background-color: #fa7e61 !important;
}
.grayText {
  color: #868da6;
}

.grayTextForce {
  color: #868da6 !important;
}
  