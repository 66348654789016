body{
    background-color:#0E1C4E0A !important;  
}

.heading {
    font-size: 21px !important;
    text-align: center;
    font-weight: 600 !important;
    margin-top: 20px !important; 
  
}
.subHeading{
    font-weight: 600 !important;
    margin-top: 12px !important;
    
}



.container{
   width: 1100px !important;
   margin: auto !important;
   margin-bottom: 30px !important;
}

.para{
    margin-top: 15px !important;
    
    
}

.li{
    margin-top: 10px;
}
.customBold{
    font-weight: 600;
    text-decoration: underline;
}