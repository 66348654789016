.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiChip-root .MuiChip-deleteIcon {
  color: #00000099;
}
.MuiChip-root {
  margin-right: 4px;
}

.MuiFormControl-root .MuiInputBase-input.Mui-disabled {
  opacity: 0.5;
}

.MuiFormControl-root .MuiInputBase-input {
  color: #0e1c4e;
}

.navyBlueTableRow .MuiTableCell-head {
  color: #0e1c4e;
}

.MuiSwitch-root.sqGraySwitch .MuiSwitch-track {
  background-color: #000;
}

.MuiSwitch-root.sqGraySwitch
  .MuiSwitch-colorSecondary.Mui-checked
  + .MuiSwitch-track {
  background-color: #000;
}

.MuiSwitch-root.sqGraySwitch .MuiSwitch-colorSecondary.Mui-checked {
  color: #0e1c4e;
}

.sqCustomTab {
  border-bottom: 2px solid rgb(245, 245, 248);
}
.sqCustomTab .MuiTab-wrapper {
  flex-direction: row;
}

.sqCustomTab .MuiTab-labelIcon {
  min-height: unset;
  height: 36px;
  color: #0e1c4e;
}

.sqCustomTab .MuiTab-labelIcon .MuiSvgIcon-root {
  margin-right: 4px;
}
.sqCustomTab .MuiTabs-indicator {
  display: none;
}

.sqCustomTab .MuiTab-textColorInherit {
  opacity: 1;
}

.sqCustomTab .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-bottom: 0px;
}

.sqCustomTab .MuiTabs-fixed {
  display: flex;
  align-items: flex-end;
}

.sqTwoLineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.uploadTable .MuiTableCell-root {
  padding: 8px;
}

.sqBlueRadio.MuiRadio-colorSecondary.Mui-checked {
  color: #0e1c4e;
}

.MuiInputLabel-root.MuiInputLabel-outlined {
  color: rgba(14, 28, 78, 0.5);
}
/* .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  visibility: hidden;
} */

.sqCVSReader input + div {
  border: none !important;
  padding: 0px !important;
  align-items: flex-start !important;
  display: inline-block !important;
}

.sq-skip-btn.MuiButton-outlined.Mui-disabled {
  opacity: 0.8;
}

.sqCustomDocAlign [aria-label="doc-tabs-container"] {
  justify-content: space-around;
}

#summary-dialog .MuiAccordionSummary-content {
  overflow: hidden;
}

.hideScroll::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.hideScroll {
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;
}

.review-comments .review-comments-anchor {
  text-decoration: none;
}

.sq-emoji-container .emoji-mart {
  width: 100%;
  position: relative;
  bottom: 100px;
  right: 20px;
  height: 300px;
}

.sq-emoji-container .emoji-mart-search,
.sq-emoji-container .emoji-mart-category[aria-label="Frequently Used"] {
  display: none;
}

.sq-emoji-container .emoji-mart-scroll {
  height: calc(100% - 50px);
}
.rbc-calendar {
  color: #0e1c4e;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #0e1c4e !important;
}

.rbc-calendar .rbc-current-time-indicator::after {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  background-color: rgb(252, 9, 9);
  border-radius: 12px;
  left: -4px;
  top: -5px;
}

body.hide-sq-intercom-icon .intercom-lightweight-app {
  display: none !important;
}

.recharts-legend-item-text {
  color: #0e1c4e !important;
}

#temp-container img.zoomed {
  transform: scale(1.5);
}

  /* yoyo-container */